body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000;
  background-image: radial-gradient(40% 40% at -2% 56%, #EDA8DC1F 0%, #073AFF00 72%),
                    radial-gradient(40% 40% at 96% -5%, #429BE145 0%, #073AFF00 87%),
                    radial-gradient(40% 40% at 102% 70%, #D887F129 0%, #073AFF00 80%),
                    radial-gradient(40% 40% at 33% -3%, #6DF05221 0%, #073AFF00 83%) !important;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.radix-themes.dark:not(.rt-DialogOverlay) {
  background-color: transparent !important;
  background-size: 100% 100%;
  background-position: 0px 0px,0px 0px,0px 0px,0px 0px;
}

.rt-variant-surface {
  --card-border-width: 0px !important;
}

.rt-DialogContent {
  box-shadow: none !important;
}